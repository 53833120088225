import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Bell from '@ast/magma/components/icon/icons/Bell';
import Envelope from '@ast/magma/components/icon/icons/Envelope';

import { DropDownMenu } from '@ast/magma/components/dropdownmenu';
import { Menu } from '@ast/magma/components/menu';

import { useMessagesCountContext } from '@app/core/asyncNotifications/MessagesCountContext';
import { t } from '@app/core/i18n';

import { MessageCenterURL } from '@app/widgets/message-center/pages/MessageCenter/Pages';

import { MessageNotificationMenuButton } from './MessageNotificationMenuButton';
import { MenuItemProps, MessageNotificationMenuItem } from './MessageNotificationMenuItem';

const menuItems: MenuItemProps[] = [{
  key: 'Alerts',
  title: t('message-notification-menu.menu-item.alerts|Alerts menu item', 'Alerts'),
  icon: <Bell />,
  route: MessageCenterURL.ALERTS,
},
{
  key: 'Messages',
  title: t('message-notification-menu.menu-item.messages|Messages menu item', 'Messages'),
  icon: <Envelope />,
  route: MessageCenterURL.MESSAGES,
}];

export const MessageNotificationMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const messagesData = useMessagesCountContext();

  // Handle menu item select
  const selectMenuItem = ({ route }: any) => {
    setIsOpen(false);
    history.push(route);
  };

  return (
    <DropDownMenu
      onOpenChange={(open: boolean) => {
        setIsOpen(open);
      }}
      isOpen={isOpen}
      dropWidth="unset"
      buttonRenderer={() => (
        <MessageNotificationMenuButton
          unreadTotal={messagesData?.TotalUnreadMessages}
          data-stable-name="BellMessagesMenuButton"
        />
      )}
    >
      <Menu
        autoFocus={false}
        onSelect={selectMenuItem}
      >
        {menuItems.map((item) => (
          <MessageNotificationMenuItem
            key={item.key}
            data={messagesData}
            item={item}
            aria-selected={location.pathname.includes(item.route)}
          />
        ))}
      </Menu>
    </DropDownMenu>
  );
};
